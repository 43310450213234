import classNames from "classnames"
import { graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { useWindowSize } from "react-use"

import EffectsLegendHoc from "../components/effects-legend/effects-legend"
import { ExpandableContainer } from "../components/expandable-container/expandable-container"
import DefaultGallery from "../components/gallery/default-gallery/default-gallery"
import {
  GalleryPictureItem,
  GalleryVideoItem,
  GalleryZoomPictureItem,
} from "../components/gallery/model/gallery-item"
import PageHeader from "../components/page-header/page-header"
import ProductComparison from "../components/product/product-comparison/product-comparison"
import ProductDetailHeader from "../components/product/product-detail-header/product-detail-header"
import {
  filterProductParameterGroupsByProductContext,
  filterProductParameterGroupsByProducts,
  filterProductsByProductContext,
} from "../components/product/product-param-helpers"
import ProductParameterTable from "../components/product/product-parameter-table/product-parameter-table"
import FileDownloads from "../components/resources/file-downloads/file-downloads"
import RichText from "../components/rich-text/rich-text"
import SEO from "../components/seo"
import Tile from "../components/tile/tile"
import TileContentSpacer from "../components/tile/tile-content-spacer/tile-content-spacer"
import useDownloads from "../hooks/useDownloads"
import useMarkets from "../hooks/useMarkets"
import useProductParameterGroups from "../hooks/useProductParameterGroups"
import useProducts from "../hooks/useProducts"
import { Locale } from "../i18n/locale"
import { localizedNavigate } from "../i18n/localizedNavigate"
import { useTranslation } from "../i18n/translate"
import { DownloadModel } from "../model/downloadModel"
import { FileModel } from "../model/fileModel"
import { ProductModel } from "../model/productModel"
import "./product.sass"

const SIMILAR_PRODUCTS_CUTOFF = 18.8

const ProductPage = ({ location, data, getFilter }) => {
  const filterKey = location.state ? location.state.filterKey : undefined

  const { t, i18n } = useTranslation()
  const currentLocale = new Locale(i18n.language)
  const productContext = new URLSearchParams(location.search)

  let similarProducts = filterProductsByProductContext(
    useProducts(true),
    productContext,
  )

  const product = new ProductModel(data.strapiProduct)
  const markets = useMarkets()

  if (similarProducts.findIndex((p) => p.id === product.id) === -1) {
    similarProducts.push(product)
  }

  let filters = []
  if (filterKey) {
    filters = getFilter(filterKey)
      .filter((f) => f._isActive)
      .map((f) => f._id)
  }

  // cutoff products with distance over SIMILAR_PRODUCTS_CUTOFF
  similarProducts = similarProducts.filter(
    (p) => product.distanceTo(p, filters) < SIMILAR_PRODUCTS_CUTOFF,
  )

  similarProducts.sort(ProductModel.getComparator(product, filters))

  similarProducts = similarProducts.slice(0, 6)

  let productParamGroups = filterProductParameterGroupsByProducts(
    useProductParameterGroups(),
    similarProducts,
  ).concat([
    {
      name: { key: "MARKETS-MARKET" },
      info: { key: "PRODUCTS-PRODUCT_FILTERS-MARKET-INFO" },
      strapiId: -2,
      prefix: null,
      suffix: null,
      type: "ENUM",
      order: 110,
    },
  ])

  productParamGroups = filterProductParameterGroupsByProductContext(
    productParamGroups,
    productContext,
  )

  const tds_file = DownloadModel.fromFile(
    FileModel.fromStrapi({
      strapiId: -1,
      ...(data.strapiProduct.tds || {}),
      name: { key: "PRODUCTS-TECHNICAL_DATA_SHEET" },
    }),
  )
  if (tds_file.pdf_file.files.length <= 0) {
    tds_file.pdf_file.additionalLinks = [
      {
        label: "PRODUCTS-REQUEST_TECHNICAL_DATA_SHEET",
        callback: () => {
          localizedNavigate(
            `/request-datasheet/?type=tds&p=${product.name}&pid=${product.id}`,
          )
        },
      },
    ]
  }

  const msds_file = DownloadModel.fromFile(
    FileModel.fromStrapi({
      strapiId: -2,
      ...(data.strapiProduct.msds || {}),
      name: { key: "PRODUCTS-MATERIAL_SAFETY_DATA_SHEET" },
    }),
  )
  if (msds_file.pdf_file.files.length <= 0) {
    msds_file.pdf_file.additionalLinks = [
      {
        label: "PRODUCTS-REQUEST_MATERIAL_SAFETY_DATA_SHEET",
        callback: () => {
          localizedNavigate(
            `/request-datasheet/?type=msds&p=${product.name}&pid=${product.id}`,
          )
        },
      },
    ]
  } else if (
    msds_file.pdf_file.files.length < Locale.getAllMsdsLocales().length
  ) {
    msds_file.pdf_file.additionalLinks = [
      {
        label: "PRODUCTS-REQUEST_MATERIAL_SAFETY_DATA_SHEET_LANGUAGE",
        callback: () => {
          localizedNavigate(
            `/request-datasheet/?type=msds&p=${product.name}&pid=${product.id}`,
          )
        },
      },
    ]
  }

  const linkedDownloads = useDownloads().filter((download) => {
    if (
      download.related_products.findIndex((r) => +r.id === product.id) !== -1
    ) {
      return true
    }

    if (
      download.markets.findIndex(
        (market) =>
          market.id &&
          product.markets.findIndex((m) => m.id === market.id) !== -1,
      ) !== -1 &&
      download.brands.findIndex(
        (brand) => brand.id && brand.id === +product.brand.id,
      ) !== -1
    ) {
      if (download.application) {
        return (
          product.enum_product_parameter_values.findIndex(
            (p) => +p.id === +download.application.id,
          ) !== -1
        )
      }
      return true
    }

    return false
  })

  const groupedDownloads = [
    {
      titleTranslatedString: t("GLOBAL-DATASHEETS"),
      downloads: [tds_file, msds_file],
    },
    {
      titleTranslatedString: t("GLOBAL-GENERAL_RESOURCES"),
      downloads: product.additional_downloads.map((pdf) =>
        DownloadModel.fromFile(FileModel.fromStrapi(pdf)),
      ),
    },
    {
      titleTranslatedString: t("GLOBAL-PRODUCT_BROCHURES"),
      downloads: linkedDownloads.filter(
        (download) => download.type === "PRODUCT_BROCHURE",
      ),
    },
    {
      titleTranslatedString: t("GLOBAL-TECHNICAL_INFORMATION"),
      downloads: linkedDownloads.filter(
        (download) => download.type === "TECHNICAL_INFORMATION",
      ),
    },
  ]

  const gallery = []

  // check the video resources which can be provided in different languages
  // and add the video in the preferred language of the user to the gallery array.
  if (product.video_resources) {
    const preferredLanguage = currentLocale.getLocaleKey()

    for (const resource of product.video_resources) {
      const galleryItem = resource.getGalleryItem(preferredLanguage)
      if (galleryItem) {
        gallery.push(galleryItem)
      }
    }
  }

  if (data.strapiProduct.image_resources) {
    for (let { image, description } of data.strapiProduct.image_resources) {
      gallery.push(
        new GalleryPictureItem({
          gatsbyFluidImage: image.localFile.childImageSharp.gatsbyImageData,
          descriptionTranslationKey: description?.key,
        }),
      )
    }
  }

  if (data.strapiProduct.zoom_image_resources) {
    for (let { image, zoomed_image, description } of data.strapiProduct
      .zoom_image_resources) {
      gallery.push(
        new GalleryZoomPictureItem({
          height: image.height,
          width: image.width,
          gatsbyFluidImage: image.localFile.childImageSharp.gatsbyImageData,
          zoomedImageSrc: zoomed_image.localFile.publicURL,
          descriptionTranslationKey: description?.key,
        }),
      )
    }
  }

  let contextAsString = ""
  if (productContext.has("market")) {
    const market = markets.find((m) => m.id === productContext.get("market"))
    contextAsString = `${market?.name}`
  }

  const schema = (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `{
            "@context": "https://schema.org/",
            "@type": "Product",
            "@id": "${
              data.site.siteMetadata.siteUrl + "/#" + product.getUrlifiedName()
            }",
            "name": "${product.name}",
            "brand":{"@id": "${
              data.site.siteMetadata.siteUrl + "/#" + product.brand.url
            }",},
        }`,
      }}
    ></script>
  )

  const windowSize = useWindowSize()
  const tableMinHeight = 200
  const [tableHeight, setTableHeight] = useState(tableMinHeight)
  const measureTableContainerRef = useRef()
  const measureResourcesContainerRef = useRef()
  const measureTableRef = useRef()

  useEffect(() => {
    if (
      measureResourcesContainerRef &&
      measureResourcesContainerRef.current &&
      measureTableContainerRef &&
      measureTableContainerRef.current &&
      measureTableRef &&
      measureTableRef.current
    ) {
      const { height: tableContainerHeight } =
        measureTableContainerRef.current.getBoundingClientRect()
      const { height: resourcesContainerHeight } =
        measureResourcesContainerRef.current.getBoundingClientRect()
      const { height: measuredTableHeight } =
        measureTableRef.current.getBoundingClientRect()

      const collapsedTableContainerHeight =
        tableContainerHeight - measuredTableHeight + tableHeight

      if (
        collapsedTableContainerHeight < resourcesContainerHeight ||
        (collapsedTableContainerHeight > resourcesContainerHeight &&
          tableHeight > tableMinHeight)
      ) {
        setTableHeight(
          Math.max(
            tableHeight +
              (resourcesContainerHeight - collapsedTableContainerHeight),
            tableMinHeight,
          ),
        )
      }
    }
  }, [measureTableContainerRef, measureResourcesContainerRef, windowSize])

  return (
    <>
      {schema}
      <SEO
        pathName={location.pathname}
        titleTranslatedString={product.name}
        descriptionTranslatedString={t(product.description.key)}
      />
      <PageHeader levelTwoTitle={product.name} levelThreeTitle={product.name} />

      <div className="_fp-global-container _fp-grid--margin-top">
        <EffectsLegendHoc
          products={similarProducts}
          productParamGroups={productParamGroups}
        >
          <div className="_fp-grid _fp-grid--gap _fp-grid--start">
            {/* make tile full-width if product has no resources (resource tile is hidden) */}
            <div
              className={classNames(
                "_fp-col-7",
                "_fp-col-12p",
                "_fp-col-12st",
                "_fp-col-12sp",
              )}
              ref={measureTableContainerRef}
            >
              <Tile titleComponent={<ProductDetailHeader product={product} />}>
                {t(product.description.key) && (
                  <div>
                    <TileContentSpacer />
                    <p className={"_fp-text _fp-text--columns"}>
                      <RichText textTranslationKey={product.description.key} />
                    </p>
                  </div>
                )}

                <TileContentSpacer />

                <div ref={measureTableRef}>
                  <ExpandableContainer
                    height={tableHeight}
                    showOverlay={true}
                    showCollapseOverlay={true}
                  >
                    <ProductParameterTable
                      product={product}
                      productParamGroups={productParamGroups}
                    ></ProductParameterTable>
                  </ExpandableContainer>
                </div>
              </Tile>
            </div>

            {/* Only show resources tile if product has resources */}
            <div
              className="_fp-col-5 _fp-col-12p _fp-col-12st _fp-col-12sp"
              ref={measureResourcesContainerRef}
            >
              <Tile titleTranslatedString={t("GLOBAL-RESOURCES")}>
                {gallery.length > 0 && (
                  <>
                    <DefaultGallery items={gallery} />
                    <TileContentSpacer />
                  </>
                )}
                <FileDownloads
                  groupedDownloads={groupedDownloads}
                  fileStyle={"gray"}
                />
              </Tile>
            </div>
            {/* Don't show product comparison for not listed products */}
            {product.show_in_productlist && similarProducts.length > 1 && (
              <div className="_fp-col-12">
                <ProductComparison
                  filterKey={filterKey}
                  titleTranslatedString={t("PRODUCTS-SIMILAR_PRODUCTS")}
                  showTile={true}
                  products={similarProducts}
                  productParamGroups={productParamGroups}
                >
                  {contextAsString && (
                    <p className={"_fp-similar-products-based-on"}>
                      {t("PRODUCTS-SIMILAR_PRODUCTS-BASED_ON")}
                    </p>
                  )}
                </ProductComparison>
              </div>
            )}
          </div>
        </EffectsLegendHoc>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    getFilter: (key) => {
      if (state.filters && state.filters[key]) {
        return state.filters[key].state || []
      }
      return []
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage)

export const query = graphql`
  query ($productId: Int) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiProduct(strapiId: { eq: $productId }) {
      ...ProductParamsFragment
      tds {
        ...PdfFileParams
      }
      msds {
        ...PdfFileParams
      }
      additional_downloads {
        ...PdfFileParams
      }
      video_resources {
        description {
          key
        }
        video_resource_items {
          language
          youtubeId
        }
      }
      image_resources {
        description {
          key
        }
        image {
          width
          height
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      zoom_image_resources {
        description {
          key
        }
        image {
          width
          height
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        zoomed_image {
          width
          height
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
