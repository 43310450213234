import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../../i18n/translate"
import GlobeIcon from "../../../images/icons/globe.svg"
import { ProductModel } from "../../../model/productModel"
import Badge from "../../badge/badge"
import CartButton from "../actions/cart-button/cart-button"
import ComparisonButton from "../actions/comparison-button/comparison-button"
import "./product-detail-header.sass"

const ProductDetailHeader = ({ product }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={"_fp-product-detail-header"}>
        <div className={"_fp-product-detail-header__badge"}>
          {product.hasAvailability() ? (
            <Badge
              badgeSvgIconComponent={<GlobeIcon />}
              textTranslatedString={product.getAvailabilityString(t)}
            />
          ) : (
            <Badge
              badgeSvgIconComponent={<GlobeIcon />}
              textTranslatedString={t(
                "PRODUCTS-AVAILABILITY-AVAILABILITY_ON_REQUEST",
              )}
            />
          )}
        </div>
        <h3 className={"_fp-heading-3 _fp-product-detail-header__title"}>
          {t("PRODUCTS-PRODUCT_DETAILS")}
        </h3>
        <div className="_fp-product-detail-header__actions">
          <ComparisonButton product={product} />
          <CartButton product={product} />
        </div>
      </div>
    </>
  )
}

ProductDetailHeader.propTypes = {
  product: PropTypes.instanceOf(ProductModel).isRequired,
}

export default ProductDetailHeader
