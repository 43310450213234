import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../../i18n/localized-link"
import { withTranslation } from "../../../i18n/translate"
import ArrowLeftIcon from "../../../images/icons/arrow_left.svg"
import ArrowRightIcon from "../../../images/icons/arrow_right.svg"
import { ProductModel } from "../../../model/productModel"
import trackCustomEvent from "../../../util/trackCustomEvent"
import Info from "../../info/info"
import RichTextPreview from "../../rich-text/rich-text-preview"
import Tile from "../../tile/tile"
import CartButton from "../actions/cart-button/cart-button"
import ComparisonButton from "../actions/comparison-button/comparison-button"
import ProductParam from "../product-param"
import { filterProductParameterGroupsByProducts } from "../product-param-helpers"
import "./product-parameter-table.sass"

class ProductParameterTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSSr: true,
      isFullWidth: false,
      leftButtonActive: false,
      rightButtonActive: true,
      heights: [],
    }

    this.productsToUse = [new ProductModel(this.props.product)]
    this.filteredProductParamGroups = filterProductParameterGroupsByProducts(
      this.props.productParamGroups,
      this.productsToUse,
      this.props.t,
    ).filter((group) => !group.hidden)
  }

  render() {
    const { t } = this.props
    this.productsToUse = [new ProductModel(this.props.product)]

    return (
      <div className="_fp-product-parameter-table">
        {this.filteredProductParamGroups.map((group, index) => {
          const product = this.productsToUse[0]
          return (
            <div
              className={"_fp-product-parameter-table__row"}
              key={"product-parameter-table-" + index}
            >
              <div className="_fp-product-parameter-table__cell _fp-product-parameter-table__cell--title">
                {group.type === "EFFECT"
                  ? t("PRODUCTS-PRODUCT_FILTERS-EFFECT_COLON")
                  : ""}
                {group.name && t(group.name.key)}
                {group.info && (
                  <Info
                    customClassName={
                      "_fp-product-comparison__property-names__info"
                    }
                    label={t(group.info.key)}
                  />
                )}
              </div>

              <div className="_fp-product-parameter-table__cell _fp-product-parameter-table__cell--value">
                <ProductParam
                  group={group}
                  param={product.getParamValues(group)}
                  type={group.type}
                  vertical={true}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

ProductParameterTable.propTypes = {
  product: PropTypes.any,
  productParamGroups: PropTypes.any,
}

export default withTranslation()(ProductParameterTable)
